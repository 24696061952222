import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import registerIcon from '../../assets/images/how-it-works/register-icon.png';
import Image from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import raw from 'rehype-raw';
import gfm from 'remark-gfm';

function HowItWork() {

    const query = graphql` {
        strapiHowItWork {
            featureHowItWork {
              stepText
              stepTitle
            }
            title
            howItWorkImage {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    }`

    const data = useStaticQuery(query);

    return (
        <section className="how-its-work-area ptb-100">
            <div className="section-title">                              
                <h2>{data.strapiHowItWork.title}</h2>
            </div>
            <div className="row align-items-center">
                <div className="col">
                    <div className="how-its-work-image">
                        <Image fluid={data.strapiHowItWork.howItWorkImage.childImageSharp.fluid} />
                    </div>
                </div>
                <div className="col">
                    <div className="how-its-work-content">
                        <div className="inner-box">
                            {data.strapiHowItWork.featureHowItWork.map((feature, index) => {
                                return(
                                    <div className="single-item" key={index}>
                                        <img alt='registerIcon' src={registerIcon}></img>
                                        <h3>{index}. {feature.stepTitle}</h3>
                                        <ReactMarkdown children={feature.stepText} remarkPlugins={[gfm]} rehypePlugins={[raw]} />
                                    </div>
                                );
                            })}
                            {/* <div className="single-item documents">
                            <img src="/images/how-it-works/documents-icon.png"></img>
                                <h3>2. Dokumentumok</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit. Lorem ipsum dolor sit amet consectetur adipiscing elit. Lorem ipsum dolor sit amet consectetur adipiscing elit. </p>
                            </div>
                            <div className="single-item consulting">
                            <img src="/images/how-it-works/consulting-icon.png"></img>
                                <h3>3. Tanácsadás</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipiscing elit. Lorem ipsum dolor sit amet consectetur adipiscing elit. </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HowItWork;