import React from 'react'
import { Col, Row } from 'react-bootstrap';
import { graphql, useStaticQuery } from "gatsby";
import ReactMarkdown from 'react-markdown';
import raw from 'rehype-raw';
import gfm from 'remark-gfm';

function Testimonials() {
    const query = graphql` {
        strapiTestimonials {
            detail
            title
            brands {
                brand_logo {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }`

    const data = useStaticQuery(query);

    return (
        <section className="testimonials-area bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>{data.strapiTestimonials.title}</h2>
                    <ReactMarkdown children={data.strapiTestimonials.detail} remarkPlugins={[gfm]} rehypePlugins={[raw]} />
                </div>
                <Row>
                    {data.strapiTestimonials.brands.map((brand, index) => {
                        return (
                            brand.brand_logo ?
                                <Col key={index} className="col-lg-4 col-xs-12">
                                    <img src={brand.brand_logo.childImageSharp.fluid.src} />
                                </Col>
                                : null
                        );
                    })}
                </Row>
            </div>
        </section>
    )
}

export default Testimonials
