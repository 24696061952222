import React from "react";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import OurSolutions from "../components/Index/OurSolutions";
import Header from '../components/Index/Header';
import HowItWork from '../components/Index/HowItWork';
import Testimonials from '../components/Index/Testimonials';
import Qualities from '../components/Index/Qualities';
import AffectsYou from '../components/Index/AffectsYou'
import '../custom_styles/styles.scss';

import '@fortawesome/fontawesome-svg-core/styles.css';
import { config } from '@fortawesome/fontawesome-svg-core';
config.autoAddCss = false; /* eslint-disable import/first */

function Home() {
    return (
        <Layout>
            <Navbar />
            <Header />
            <OurSolutions />
            <AffectsYou />
            <HowItWork />
            <Qualities />
            <Testimonials />
            <Footer />
        </Layout>
    );
};

export default Home;