import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { graphql, useStaticQuery } from "gatsby";
import { navigate } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

export default function AffectsYou() {

    const query = graphql` {
        strapiAffectYou {
            affectyou_title
            href_text
            route
            embed_id
            affectyou_feature {
                text
                icon
            }
        }
    }`

    const data = useStaticQuery(query);

    return (
        <div className="affect-container">
            <div className="section-title">
                <h2>{data.strapiAffectYou.affectyou_title}</h2>
            </div>
            <Row>
                {data.strapiAffectYou.embed_id ? 
                <Col>
                    <div className="video-embed">
                        <iframe src={`https://www.youtube.com/embed/${data.strapiAffectYou.embed_id}`} frameborder="0" allowfullscreen></iframe>
                    </div>
                </Col>
                : null }
                <Col className={data.strapiAffectYou.embed_id ? "list-items" : "list-items no-video"}>
                    <ul>
                        {data.strapiAffectYou.affectyou_feature.map((feature, index) => {
                            return (
                                <li key={index}><FontAwesomeIcon icon={faCheckCircle} />{feature.text}</li>
                            );
                        })}
                    </ul>
                    <a href="#" onClick={() => navigate(data.strapiAffectYou.route)}>{data.strapiAffectYou.href_text}</a>
                </Col>
            </Row>
        </div>
    );
}