import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import Image from 'gatsby-image';
import ReactMarkdown from 'react-markdown';
import raw from 'rehype-raw';
import gfm from 'remark-gfm';

const query = graphql` {
    strapiSolutions {
        solution_features {
            desc
            title
            image {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                  }
            }
            route
        }
        solutions_desc
        solutions_title
    }
}`

const OurSolutions = () => {
    const data = useStaticQuery(query)

    return (
        <section className="solutions-area pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>{data.strapiSolutions.solutions_title}</h2>
                    <ReactMarkdown children={data.strapiSolutions.solutions_desc} remarkPlugins={[gfm]} rehypePlugins={[raw]} />
                </div>

                <div className="row box-container">
                    {data.strapiSolutions.solution_features.map((solution, index) => (
                        <div className="col-lg-4 col-xs-12 single-solutions-wrapper" key={index}>
                            <div className="single-solutions-box">
                                <Image critical fadeIn={false} loading="auto" fluid={solution.image.childImageSharp.fluid} />
                                <div className="solutions-desc-wrapper">
                                    <strong>{index + 1}</strong>
                                    <div className="solution-desc">
                                        <h3>
                                            {solution.title}
                                        </h3>
                                        <ReactMarkdown children={solution.desc} remarkPlugins={[gfm]} rehypePlugins={[raw]} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </section>
    )
}

export default OurSolutions;
